<template>
  <el-drawer
    :with-header="false"
    :visible.sync="drawer"
    size="55%"
    custom-class="t_notice_drawer"
    :before-close="handleClose">
    <el-form
      :model="form"
      size="medium"
      :rules="rules"
      class="form"
      ref="t_notice_drawer_form"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入"
          :maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <QuillEditor
            @uploadFile1="uploadFile1"
            :resultMethods="'uploadFile1'"
            ref="QuillEditor"
          @quillEditorText="quillEditorText"
          @quillEditorHtml="quillEditorHtml"
        />
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer t_notice_drawer_footer">
      <el-checkbox v-model="form.checked">是否置顶</el-checkbox>

      <div>
        <el-button @click="drawerFalse">取消</el-button>
        <el-button
          type="primary"
          @click="confirm('t_notice_drawer_form')"
          :loading="loading"
        >
          确定
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import QuillEditor from "@/components/teacher/QuillEditor.vue";
export default {
  name: "t_notice_drawer",
  components: { QuillEditor },
  props: {
    data: Object,
  },
  data() {
    return {
      drawer: false,
      loading: false,
      rules: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
      },
      form: {
        title: null,
        content: null,
        checked: false,
        html:null
      },

    };
  },
  watch: {
    data(n) {
      if (n) {
        this.form = this.data;
      } else {
        this.form = {
          title: null,
          content: null,
          checked: false,
        };
      }
    },
  },
  methods: {
    uploadFile1(str){
      if (null == this.$refs['QuillEditor'].content){
        this.$refs['QuillEditor'].content =str;
      }else {
        this.$refs['QuillEditor'].content =this.$refs['QuillEditor'].content+str;
      }
    },
    sendAdd(){

    },
    confirm(refName) {
      this.$refs[refName].validate((valid) => {
       if (valid){
        this.$emit("sendAdd",this.form)
       }
      });
    },
    quillEditorText(text) {
      this.form.content = text;
    },
    quillEditorHtml(text) {
      this.form.html =  text;
    },
    drawerFalse(){
      this.drawer = false;
      this.form = {
        title: null,
        content: null,
        checked: false,
      };
    },
    setHtmlContent(html){
      this.$refs['QuillEditor'].content= html;
    },
    handleClose(done){
      done();
      this.setHtmlContent('');
      this.form = {
        title: null,
        content: null,
        checked: false,
      };
    },
  },
};
</script>

<style lang="less">
.t_notice_drawer {
  padding: 30px;
  .demo-drawer__footer.t_notice_drawer_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
