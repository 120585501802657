<style lang="less" scoped>
.t_course_notice {
  box-shadow: @shadow;
  background: #fff;

  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }

  .content {
    padding: 20px;
  }
}
</style>
<template>
  <div class="t_course_notice">
    <div class="stu-module-header">
      <div class="stu-module-title">课程公告</div>
      <div>
        <el-button type="primary" @click="add">添加公告</el-button>
        <el-button type="primary" @click="del">删除</el-button>
        <!--        <el-button type="primary" @click="top">置顶/取消</el-button>-->
        <el-button type="primary" @click="effective">有效/无效</el-button>
      </div>
    </div>
    <div class="content">
      <el-form :model="form" size="medium" inline>
        <el-form-item label="标题/发布人">
          <el-input
            v-model="form.title"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="form.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :height="450"
        ref="tableRef"
        stripe
        @selection-change="selectionChange"
      >
        <el-table-column
          type="selection"
          align="center"
          width="70"
        ></el-table-column>
        <el-table-column label="序号" :width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in columns"
          :prop="item.prop"
          :key="index"
          :label="item.label"
          :min-width="item.width"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button plain @click="detail(scope.row)">查看</el-button>
            <el-button type="primary" @click="edit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
      :title="detailData.title"
      :visible.sync="detailDrawer"
      size="55%"
    >
      <div style="padding: 20px" v-html="detailData.content"></div>
    </el-drawer>
    <NoticeDrawer
      ref="t_notice_drawer"
      :data="editData"
      @sendAdd="sendAdd($event)"
    />
  </div>
</template>

<script>
import NoticeDrawer from "./module/NoticeDrawer.vue";

export default {
  name: "t_course_notice",
  components: { NoticeDrawer },
  data() {
    return {
      form: {
        title: "",
        date: [],
      },
      selectionData: [],
      detailDrawer: false,
      detailData: {
        title: null,
        content: null,
      }, // 点击查看
      editData: null, // 点击编辑
      tableData: [
        {
          title: "测试",
          content: "测试nearing内容内容",
          createTime: "2020-10-10 14:22:11",
          people: "测试员",
          readCount: 0,
          waitReadCount: 50,
          status: "有效",
        },
      ],
      columns: [
        { prop: "title", label: "标题" },
        { prop: "publishTime", label: "发布时间", width: 120 },
        { prop: "name", label: "发布人" },
        { prop: "readNum", label: "已读人数" },
        { prop: "unreadNum", label: "未读人数" },
        { prop: "status", label: "状态" },
      ],
      pageSize: 10,
      current: 1,
      total: 0,
      editId: null,
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  activated() {
    this.query();
  },
  methods: {
    sendAdd(data) {
      if (this.editData != null) {
        let formData = new FormData();
        formData.append("title", data.title);
        formData.append("content", this.$refs['t_notice_drawer'].form.html);
        if (data.checked) {
          formData.append("upFlag", "1");
        } else {
          formData.append("upFlag", "0");
        }
        formData.append("id", this.editId);
        this.$axios_supermallData
          .put("/hnjxjy-core/eduCourseNotice/edit", formData)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.$message.success("操作成功");
              this.$refs["t_notice_drawer"].form = {
                title: null,
                content: null,
                checked: false,
              };
              this.$refs["t_notice_drawer"].drawer = false;
              this.query();
              this.$refs['t_notice_drawer'].setHtmlContent('');
            }
          });
      } else {
        let formData = new FormData();
        formData.append("title", data.title);
        formData.append("content",this.$refs['t_notice_drawer'].form.html);
        if (data.checked) {
          formData.append("upFlag", "1");
        } else {
          formData.append("upFlag", "0");
        }
        formData.append("courseId", this.course.id);
        formData.append("publishId", this.user.id);
        this.$axios_supermallData
          .post("/hnjxjy-core/eduCourseNotice/add", formData)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.$message.success("操作成功");
              this.$refs["t_notice_drawer"].form = {
                title: null,
                content: null,
                checked: false,
              };
              this.$refs['t_notice_drawer'].setHtmlContent('');
              this.$refs["t_notice_drawer"].drawer = false;
              this.query();
            }
          });
      }
    },
    add() {
      this.$refs["t_notice_drawer"].drawer = true;
      this.editData = null;
    },
    del() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请勾选数据");
      }
      let ids = "";
      this.selectionData.forEach((item) => {
        ids = ids + item.id + ",";
      });
      ids = ids.substr(0, ids.length - 1);
      let formData = new FormData();
      formData.append("ids", ids);
      this.$axios_supermallData
        .delete("/hnjxjy-core/eduCourseNotice/remove?ids=" + ids, formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.$message.success("删除成功!");
            this.query();
          }
        });
    },
    top() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请勾选数据");
      }
    },
    effective() {
      if (this.selectionData.length === 0) {
        return this.$message.error("请勾选数据");
      }

      let ids = "";
      this.selectionData.forEach((item) => {
        ids = ids + item.id + ",";
      });
      ids = ids.substr(0, ids.length - 1);

      let formData = new FormData();
      formData.append("ids", ids);
      formData.append("status", "2");
      this.$axios_supermallData
        .put("/hnjxjy-core/eduCourseNotice/editStatus", formData)
        .then((res) => {
          if (res.data.code === 2000) {
            this.$message.success("状态修改成功");
            this.query();
          }
        });
    },
    query() {
      let formData = new FormData();
      formData.append("page", this.current);
      formData.append("pageSize", this.pageSize);
      formData.append("courseId", this.course.id);
      formData.append("info", this.form.title);
      if (this.form.date != null) {
        if (this.form.date[0] instanceof Date) {
          formData.append(
            "startTime",
            this.form.date[0].formatToLocalDateTime("yyyy/MM/dd HH:mm:ss")
          );
        }
        if (this.form.date[1] instanceof Date) {
          formData.append(
            "endTime",
            this.form.date[1].formatToLocalDateTime("yyyy/MM/dd HH:mm:ss")
          );
        }
      }

      this.openLoadingView();
      this.$axios_supermallData
        .post("/hnjxjy-core/eduCourseNotice/list", formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.tableData = resp.data.data.list;
            this.total = resp.data.data.total;
        }
          this.loadingView.close();
        })
        .catch(() => {
          this.loadingView.close();
        });
    },
    detail(row) {
      if (row) {
        this.detailData = row;
        this.detailDrawer = true;
      }
    },
    edit(row) {
      if (row) {
        this.$refs["t_notice_drawer"].drawer = true;
        setTimeout(() => {
          this.$refs["t_notice_drawer"].form =  {
            title: null,
            content: null,
            checked: false,
          };
          this.openLoadingView();
        }, 100);
        setTimeout(() => {
          this.editId = row.id;
          this.openLoadingView();
          this.$axios_supermallData
              .get("/hnjxjy-core/eduCourseNotice/getOne?id=" + row.id)
              .then((resp) => {
                if (resp.data.code == 2000) {
                  this.editData = resp.data.data;
                  this.$refs['t_notice_drawer'].form.html = resp.data.data.content;
                  this.$refs['t_notice_drawer'].setHtmlContent(resp.data.data.content);
                  // if (this.editData.upFlag == 0){
                  //   this.editData.checked = false;
                  // }else {
                  //   this.editData.checked = true;
                  // }
                }
                this.loadingView.close();
              })
              .catch(() => {
                this.loadingView.close();
              });
          this.loadingView.close();
        }, 1000);
      }
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
    },
  },
};
</script>
